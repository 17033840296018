// extracted by mini-css-extract-plugin
export var colorBg = "project-overview-module--colorBg--050f7";
export var dark = "project-overview-module--dark--69792";
export var flexContainer = "project-overview-module--flexContainer--69eaf";
export var item = "project-overview-module--item--b1e7b";
export var light = "project-overview-module--light--70661";
export var opacityIn = "project-overview-module--opacity-in--f6cb8";
export var portfolioImg = "project-overview-module--portfolioImg--adbce";
export var portfolioSection = "project-overview-module--portfolioSection--1566c";
export var title = "project-overview-module--title--307b3";
export var titleBox = "project-overview-module--titleBox--09ffd";
export var titleLine = "project-overview-module--titleLine--df39b";
export var titleSection = "project-overview-module--titleSection--acb6c";
export var wrapper = "project-overview-module--wrapper--48ad9";