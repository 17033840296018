import * as React from "react"
import { graphql, Link } from "gatsby"
import * as styles from "../styles/project-overview.module.scss"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { useTranslation } from 'react-i18next'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import i18next from "i18next"
import { localizeString } from "../components/utils/localization"


function Projects({data}) {
    const { t, i18n } = useTranslation();

    let projects = data.projects.nodes;




        //ScrollMotion Value
const [isDark, setDark] = React.useState(false);
const [isMobile, setisMobile] = React.useState(false);
const [hover, setHover] = React.useState(false);


const colorRef = React.createRef();

  

    //Init
    React.useEffect(() => {

      function handleResize() {
        if(window.innerWidth >= 1200)
        {
          setisMobile(false);
        }
        else{
          setisMobile(true);
            }
      }
    //Make Ref for BG colour change
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
    }, []) 


    //This function is for background colour change
    function ChangeBG(col,makeTextWhite)
    {
      if (isMobile) return;

      setHover(true);
      let colour = "#"+col;
      colorRef.current.style.backgroundColor = colour;
      setDark(makeTextWhite);
    }

    function ChangeBGBack()
    {
      if (isMobile) return;

      colorRef.current.style.backgroundColor = 'transparent';
      setDark(false);
      setHover(false);
    }


    return (
        <Layout type = "work" isDark={isDark} isMobile={isMobile} key={i18n.language}>
            <div ref={colorRef} className={styles.colorBg}>
            <section className={styles.titleSection}>
            <div className={styles.titleBox}>
              <div className={isDark?"light":"dark"}>
                  <h1 className={styles.title}><span>{t("projects")}</span></h1>
              </div>  
                <div className={isDark? [styles.titleLine, styles.light, "light"].join(" "):[styles.titleLine, styles.dark, "dark"].join(" ")}>
                    <hr></hr>
                </div> 
                </div>
            </section>
            <section className={styles.portfolioSection}>
                <div className={styles.wrapper}>
                    <div className={styles.flexContainer}>
                  
                  
                    {projects.map(project => (
                        <div key={project.childMdx.id} className={isDark? [styles.item, "light"].join(" ") : [styles.item, "dark"].join(" ")} >
                        <Link to={localizeString("/projects/") + project.childMdx.frontmatter.slug} onMouseEnter={()=>ChangeBG(project.childMdx.frontmatter.bgColor, project.childMdx.frontmatter.makeTextWhite)} onMouseLeave={()=>ChangeBGBack()}><GatsbyImage image={getImage(project.childMdx.frontmatter.thumb)} alt={project.childMdx.frontmatter.thumbALT} className={styles.portfolioImg}/></Link>
                            <h4>{project.childMdx.frontmatter.title}</h4>
                            {project.childMdx.frontmatter.categories.map(category => (
                                <span key={category}>{category}</span>
                            ))}
                       </div>

                      ))}
                    </div>
                </div>
            </section>


            </div>
      

        </Layout>
    )
}

export const query = graphql`
query ProjectsPageQuery($locale: String) {
  projects: allFile(
    filter: {
      sourceInstanceName: { eq: "projects"}
      childMdx: { fields: { locale: { eq: $locale }}}
    },
    sort: {fields: childrenMdx___frontmatter___order, order: DESC})
    {
      nodes {
        childMdx{
        id
        frontmatter {
          title
          slug
          bgColor
          makeTextWhite
          categories 
          thumbALT
          thumb {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }  
          }
        }
        }
      }
    }
  }

`
  
  export default Projects
  
  export const Head = () => (
    <Seo title="Our Work" titleDE="Unser Portfolio" 
    description="From CMS managed e-Commerce to native mobile apps - our work showcases digital experience that move your audience."
    descriptionDE="Von CMS-gesteuerten e-Commerce Websites zu nativen Mobile-Apps - unser Portfolio zeigt digitale Erlebnisse, die in Erinnerung bleiben."/>    )